.bubbles {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 ($lh / 2);
}

.bubbles__container {
  position: relative;
  padding-bottom: 68.5582822%;
}

.bubbles__item {
  display: block;
  position: absolute;
  transition: transform ($ms * 1.5) cubic-bezier(.42,1.04,.51,1.34);
  border-radius: 50%;
  overflow: hidden;
  mix-blend-mode: multiply;
  will-change: transform;

  &:hover {
    transform: scale(1.3) rotate(-10deg);
    z-index: 1;
  }
}

@each $service, $color in $services {
  .bubbles__item--#{$service} {
    background: #{$color};
  }
}

.bubbles__item--strategy {
  top: 22.706935123%;
  left: 0;
  width: 22.929447853%;
  padding-bottom: 22.929447853%;
}

.bubbles__item--content {
  top: .383435583%;
  left: 19.248466258%;
  width: 27.22392638%;
  padding-bottom: 27.22392638%;
}

.bubbles__item--campaigns {
  top: 40.156599553%;
  left: 16.564417178%;
  width: 35.122699387%;
  padding-bottom: 35.122699387%;
}

.bubbles__item--ar {
  top: 13.982102908%;
  left: 44.325153374%;
  width: 30.521472393%;
  padding-bottom: 30.521472393%;
}

.bubbles__item--vr {
  top: -0.4%;
  left: 71%;
  width: 22.622699387%;
  padding-bottom: 22.622699387%;
}

.bubbles__item--apps {
  top: 32.102908277%;
  left: 72.699386503%;
  width: 27.300613497%;
  padding-bottom: 27.300613497%;
}

.bubbles__item--websites {
  top: 57.7%;
  left: 50.536809816%;
  width: 28.987730061%;
  padding-bottom: 28.987730061%;
}


.bubbles__name {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 0 ($lh / 2);
  transform: translateY(-50%);
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: -.02em;
  line-height: $lh * .6;
  text-align: center;

  @include mq(large) {
    padding: 0 $lh;
    font-size: $fz * $type-scale;
    line-height: $lh;
  }
}
