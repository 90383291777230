.newsletter {
  padding: ($lh * 2) 0;
  background: $color8;
}

.newsletter--light {
  background: transparent;
}

.newsletter__row {
  @include row;
}

.newsletter__col {
  @include col;

  // @include mq(max) {
  //   @include push(1 / 8);
  //   @include col(3 / 4);
  // }
}

.newsletter__container {
  position: relative;
  width: 100%;
  font: inherit;
}

.newsletter__title {
  color: #fff;
  font-weight: 700;

  .newsletter--light & {
    color: $color8;
  }
}
