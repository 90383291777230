.news {
  @include row(true);
}

.news__col {
  @include col();
  margin-bottom: $lh;

  @include mq(medium) {
    @include col(1/2);
  }

  @include mq(large) {
    @include col(1/3);
  }
}

.news__item {
  background: #fff;
  box-shadow: inset 0 0 0 1px $color9;
}

.news__imagelink {
  display: block;
  overflow: hidden;
  background: $color1;
  position: relative;

  &:hover {
    .news__image {
      transform: scale(1.025);
      opacity: .5;
    }

    .news__eye {
      transform: scale(1);
    }
  }
}


.news__image {
  transition: all $ms;
}

.news__eye {
  position: absolute;
  width: $lh * 1.6;
  height: $lh * 1.6;
  top: calc(50% - (#{$lh} * .8));
  left: calc(50% - (#{$lh} * .8));
  fill: #fff;
  transform: scale(0);
  transition: transform $ms;
}

.news__info {
  padding: 0 $lh / 2 $lh / 2;

  @include mq(max) {
    padding: ($lh / 2) $lh $lh;
  }
}

.news__date {
  font-size: $fz / $type-scale;

  @include mq(x-large) {
    font-size: $fz / $type-scale / $type-scale;
  }
}

.news__title {
  margin-bottom: 0;
}

.news__titlelink {
  color: #000;
  transition: color $ms;

  &:hover {
    text-decoration: none;
    color: $color1;
  }
}
