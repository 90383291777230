.gallery {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}

.gallery__container {
  position: relative;
  padding-bottom: 56.25%;
  box-shadow: 0 0 0 2px $color8, inset 0 0 0 2px $color8;
}

.gallery__item {
  position: absolute;
  background: #ccc;
}

.gallery--one {
  @for $i from 1 through 3 {
    .gallery__item--#{$i}) {
      top: 0;
      left: ($i - 1) * 33.3333%;
      width: 33.3333%;
      height: 33.3333%;
    }

    .gallery__item--#{$i + 3}) {
      top: 33.3333%;
      left: ($i - 1) * 33.3333%;
      width: 33.3333%;
      height: 33.3333%;
    }

    .gallery__item--#{$i + 6}) {
      top: 66.66666%;
      left: ($i - 1) * 33.3333%;
      width: 33.3333%;
      height: 33.3333%;
    }
  }
}

.gallery--two {
  .gallery__item--1 {
    top: 0;
    left: 0;
    width: 33.3333%;
    height: 33.3333%;
  }

  .gallery__item--2 {
    top: 0;
    left: 33.3333%;
    width: 66.6666%;
    height: 66.6666%;
  }

  .gallery__item--3 {
    top: 33.3333%;
    left: 0;
    width: 33.3333%;
    height: 33.3333%;
  }

  .gallery__item--4 {
    top: 66.6666%;
    left: 0;
    width: 66.6666%;
    height: 33.3333%;
  }

  .gallery__item--5 {
    top: 66.6666%;
    left: 66.6666%;
    width: 33.3333%;
    height: 33.3333%;
  }
}

.gallery--three {
  .gallery__item--1 {
    top: 0;
    left: 0;
    width: 33.3333%;
    height: 33.3333%;
  }

  .gallery__item--2 {
    top: 0;
    left: 33.3333%;
    width: 33.3333%;
    height: 33.3333%;
  }

  .gallery__item--3 {
    top: 0;
    left: 66.6666%;
    width: 33.3333%;
    height: 66.6666%;
  }

  .gallery__item--4 {
    top: 33.3333%;
    left: 0;
    width: 66.6666%;
    height: 66.6666%;
  }

  .gallery__item--5 {
    top: 66.6666%;
    left: 66.6666%;
    width: 33.3333%;
    height: 33.3333%;
  }
}

.gallery--four {
  .gallery__item--1 {
    top: 0;
    left: 0;
    width: 66.6666%;
    height: 66.6666%;
  }

  .gallery__item--2 {
    top: 0;
    left: 66.6666%;
    width: 33.3333%;
    height: 100%;
  }

  .gallery__item--3 {
    top: 66.6666%;
    left: 0;
    width: 33.3333%;
    height: 33.3333%;
  }

  .gallery__item--4 {
    top: 66.6666%;
    left: 33.3333%;
    width: 33.3333%;
    height: 33.3333%;
  }
}

.gallery--five {
  .gallery__item--1 {
    top: 0;
    left: 0;
    width: 66.6666%;
    height: 33.3333%;
  }

  .gallery__item--2 {
    top: 0;
    left: 66.6666%;
    width: 33.3333%;
    height: 33.3333%;
  }

  .gallery__item--3 {
    top: 33.3333%;
    left: 0;
    width: 33.3333%;
    height: 33.3333%;
  }

  .gallery__item--4 {
    top: 33.3333%;
    left: 33.3333%;
    width: 66.6666%;
    height: 66.6666%;
  }

  .gallery__item--5 {
    top: 66.6666%;
    left: 0%;
    width: 33.3333%;
    height: 33.3333%;
  }
}

.gallery--six {
  .gallery__item--1 {
    top: 0;
    left: 0;
    width: 33.3333%;
    height: 66.6666%;
  }

  .gallery__item--2 {
    top: 0;
    left: 33.3333%;
    width: 33.3333%;
    height: 33.3333%;
  }

  .gallery__item--3 {
    top: 0%;
    left: 66.6666%;
    width: 33.3333%;
    height: 100%;
  }

  .gallery__item--4 {
    top: 66.6666%;
    left: 0%;
    width: 33.3333%;
    height: 33.3333%;
  }

  .gallery__item--5 {
    top: 33.3333%;
    left: 33.3333%;
    width: 33.3333%;
    height: 66.6666%;
  }
}

.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border: 2px solid $color8;
}
