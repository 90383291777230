@keyframes siriEffect {
  0% {
    transform: translate3d($lh / 4, 0, 0);
    opacity: 0;
  }

  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes siriEffectSlow {
  0% {
    transform: translate3d($lh / 2, 0, 0);
    opacity: 0;
  }

  100% {
    transform: none;
    opacity: 1;
  }
}

.siri {
  display: inline-block;
  animation-name: siriEffect;
  animation-fill-mode: both;
  animation-duration: $ms * 2;
  animation-delay: $ms;
  animation-timing-function: ease-out;
}

.siri--slow {
  animation-name: siriEffectSlow;
  animation-fill-mode: both;
  animation-duration: $ms * 20;
  animation-delay: $ms;
  animation-timing-function: ease-out;
}
