.squeres {
  overflow: auto;
}

.squeres__item {
  position: relative;
  width: calc(100% / 3);
  float: left;

  @include mq(max) {
    width: calc(100% / 6);
  }

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .3);
    content: '';
  }
}

.squeres__img {
  width: 100%;
}

.squeres__name {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 0 $lh;
  transform: translateY(-50%);
  color: #fff;
  font-weight: 700;
  font-size: $fz / $type-scale;
  letter-spacing: -.02em;
  line-height: $lh / 2;
  text-align: center;
  text-transform: uppercase;
}
