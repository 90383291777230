@keyframes processShow {
  0% {
    transform: scale(1.05);
    opacity: 0;
  }

  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes processfadeOut {
  0% {
    transform: none;
    opacity: 1;
  }

  100% {
    transform: scale(1.05);
    opacity: 0;
  }
}

.process {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #eee;
  z-index: 30;
  animation-name: processShow;
  animation-duration: $ms;
  animation-fill-mode: both;
}

.process--active {
  display: block;
}

.process--fadeout {
  animation-name: processfadeOut;
  animation-duration: $ms;
  animation-fill-mode: both;
}

.process__close {
  position: absolute;
  top: $lh / 2;
  right: $lh / 2;
  width: $lh * 2;
  height: $lh * 2;
  transform: rotate(45deg);
  transition: $ms all;
  border: 0;
  border-radius: 50%;
  outline: 0;
  background-color: #fff;
  font: inherit;
  font-weight: 700;
  letter-spacing: -.02em;
  box-shadow: 0 ($lh / 4) ($lh / 2) rgba(0, 0, 0, .1);
  cursor: pointer;
  z-index: 31;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: $fz;
    height: 4px;
    transform: translate(-50%, -50%);
    transition: all $ms;
    background-color: $color8;
    content: '';
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: $fz;
    height: 4px;
    transform: translate(-50%, -50%) rotate(90deg);
    transition: all $ms;
    background-color: $color8;
    content: '';
  }

  &:hover {
    background-color: $color1;

    &::before {
      transform: translate(-50%, -50%) rotate(-90deg);
      background-color: #fff;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(180deg);
      background-color: #fff;
    }
  }
}

.process__carousel {
  position: relative;
}

.process__nav {
  display: none;
  position: absolute;
  top: 50%;
  width: $lh * 2;
  height: $lh * 2;
  transform: translateY(-50%);
  transition: $ms background-color;
  border: 0;
  border-radius: 50%;
  outline: 0;
  background-color: #fff;
  box-shadow: 0 ($lh / 4) ($lh / 2) rgba($color8, .15);
  cursor: pointer;

  @include mq(max) {
    display: block;
  }

  &:hover {
    background-color: $color1;

    .process__icon {
      transform: scale(1.25);
      fill: #fff;
    }

    .process__icon--prev {
      transform: scale(1.25);
    }
  }
}

.process__nav--prev {
  left: $lh / 2;
  transform: translateY(-50%) rotate(180deg);
  box-shadow: 0 (-$lh / 4) ($lh / 2) rgba($color8, .15);
}

.process__nav--next {
  right: $lh / 2;
}

.process__icon {
  width: $lh;
  height: $lh;
  transition: $ms all;
  fill: $color8;
}

.process__icon--prev {
  width: $lh;
  height: $lh;
  transform: rotate(180deg);
}

.process__step {
  display: flex;
  position: relative;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: $color9;
}

.process__middle {
  width: 100%;
}

.process__row {
  @include row;
}

.process__col {

  margin-bottom: $lh * 5;
  text-align: center;
  @include col;

  @include mq(large) {
    @include col(1 / 2);
    @include push(1 / 4);
  }
}

.progress__line {
  position: absolute;
  bottom: $lh * 3;
  height: $lh * 2;
  overflow: visible;
}

.progress__line--1 {
  right: 0;
  width: 50%;
  background: #b0be12;
}

.progress__line--2 {
  left: 0;
  width: 100%;
  background-image: linear-gradient(to right, #b0be12, #b0be12 50%, #eb661c 50%, #eb661c);
}

.progress__line--3 {
  left: 0;
  width: 100%;
  background-image: linear-gradient(to right, #eb661c, #eb661c 50%, #f2a100 50%, #f2a100);
}

.progress__line--4 {
  left: 0;
  width: 100%;
  background-image: linear-gradient(to right, #f2a100, #f2a100 50%, #9d215a 50%, #9d215a);
}

.progress__line--5 {
  left: 0;
  width: 100%;
  background-image: linear-gradient(to right, #9d215a, #9d215a 50%, #c61459 50%, #c61459);
}

.progress__line--6 {
  left: 0;
  width: 50%;
  background: #c61459;
}

.process__diagram {
  width: 90%;
  max-height: 90vh;
  margin: 0 auto;

  @include mq(large) {
    width: 70%;
  }
}

.process__stepcircle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: $lh * 4;
  height: $lh * 4;
  padding: $lh / 2;
  transform: translate(-50%, -50%);
  border-radius: 50%;

  @include mq(medium) {
    width: $lh * 6;
    height: $lh * 6;
  }

  @include mq(large) {
    width: $lh * 7;
    height: $lh * 7;
  }
}

.process__stepcircle--1 {
  left: 0;
  background: #b0be12;
}

.process__stepcircle--2 {
  background: #eb661c;
}

.process__stepcircle--3 {
  background: #f2a100;
}

.process__stepcircle--4 {
  background: #9d215a;
}

.process__stepcircle--5 {
  background: #c61459;
}

.process__stepcircle--6 {
  left: 100%;
  background: #c61459;
}

.process__stepimg {
  border-radius: 50%;
}
