.timeline {
  background: #9d215a;
  color: #fff;
  overflow: auto;
}

.timeline__col {
  width: 100%;
  max-width: 1280px;
  margin: ($lh * 3) auto;
  padding: 0 ($lh / 2);
}

.timeline__title,
.timeline__text {
  text-align: center;
  color: #fff;
}

.timeline__svg {
  width: 100%;
}

.timeline__circle {
  display: none;
  cursor: pointer;
}

.timeline__trigger {
  cursor: pointer;
}
