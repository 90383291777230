.portfolio-case {
  position: relative;
  margin-top: $lh * 3;
  padding: $lh 0 ($lh * 2);
  border-radius: $lh;
  background-color: darken($color8, 6);
  color: $color-bg;
  box-shadow: 0 30px 70px 0 rgba(0, 20, 20, .16);

  @include mq(medium) {
    margin-top: $lh * 4;
    border-radius: $lh * 1.5;
  }

  &::before {
    position: absolute;
    top: -160px;
    left: 50%;
    width: 50%;
    height: 161px;
    transform: translateX(-50%);
    background-image: url('../images/handle.svg');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
    content: '';
  }
}

.portfolio__center {
  max-width: 1920px;
  margin: 0 auto;
  background-color: darken($color8, 6);
}

.portfolio__top {
  position: relative;
}

.portfolio__title {
  color: #fff;
}

.portfolio__current {
  display: inline-block;
  position: relative;
  transition: color .14s;
  color: #898989;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: 600;
  letter-spacing: 0.02rem;
  border: 1px solid #898989;
  padding: 0.2rem 1.2rem;
  transition: all 0.3s;

  &:hover{
    color:white;
    background-color: #898989;
  }
}

.port-row{
  padding-bottom: 0 !important;
  max-width: 1200px;
}

.browse-text{
  padding:1.3rem 0.75rem 0.6rem 0.75rem;
  display: block;
}

.portfolio__current--latest {
  color: tint($color-text, 30);
}

@each $service, $value in $services {
  .portfolio__current--#{$service} {
    color:white;

    strong{
      color: #{$value};
    }
  }
}

.portfolio__options {
  position: absolute;
  top: 4.8rem;
  left: 0;
  width: 100%;
  margin: 0;
  transform: scale(0);
  transition: all $ms;
  border-radius: 5px;
  background: #fff;
  font-size: $fz;
  list-style: none;
  opacity: 0;
  z-index: 2;
}

.portfolio__options--active {
  margin: 0.8rem 0 0 0;
  transform: none;
  opacity: 1;
}

@keyframes showOption {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.portfolio__option {
  display: inline-block;
  margin: 0 ($lh / 4);

  .portfolio__options--active & {
    animation-name: showOption;
    animation-delay: $ms * 2;
    animation-duration: $ms;
    animation-fill-mode: both;
  }
}

.portfolio__optionlink {
  display: block;
  padding: $lh / 4;
  transition: color $ms;
  color: $color-text;
  font-size: $fz / $type-scale;
  font-weight: 700;
  letter-spacing: -.02em;

  &::before {
    margin-right: $lh / 4;
    color: tint($color-text, 30);
    content: '•';
  }

  &:hover {
    color: tint($color-text, 30);
    text-decoration: none;
  }
}

@each $service, $color in $services {
  .portfolio__optionlink--#{$service} {
    &:hover {
      color: #{$color};
    }

    &::before {
      color: #{$color};
    }
  }
}

.portfolio__row {
  padding-top: 0.2rem !important;

  @include row;

  @include mq(large) {
    max-width: none;
  }
}

.portfolio__col {
  @include col;
  padding: 0;

  @include mq(large) {
    @include col(1/2);
    padding: 0;
  }
}
