.l-row {
  @include row;
}

.l-full {
  @include col;
}

.l-half {
  @include col;
  @include mq(large) {
    @include col(1 / 2);
  }
}

.l-onethird {
  @include col;
  @include mq(large) {
    @include col(1 / 3);
  }
}

.l-onequarter {
  @include col;
  @include mq(large) {
    @include col(1 / 4);
  }
}

.l-sidebar {
  @include col;
  @include mq(large) {
    @include col(1 / 3);
  }
}

.l-main {
  @include col;
  @include mq(large) {
    @include col(2 / 3);
  }
}
