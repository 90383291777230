.enginnovation {
  @include row;
}

.enginnovation__col {
  @include col;

  @include mq(large) {
    @include col(2/3);
    @include push(1/6);
  }
}

.enginnovation__bold {
  color: $color8;
  font-weight: 700;
  font-size: $fz / $type-scale;
  letter-spacing: -.02em;
}

.enginnovation__btn {
  display: inline-block;
  position: relative;
  width: $lh * 2;
  height: $lh * 2;
  transition: $ms all;
  border: 0;
  border-radius: 50%;
  outline: 0;
  background-color: #fff;
  font: inherit;
  font-weight: 700;
  letter-spacing: -.02em;
  box-shadow: 0 ($lh / 4) ($lh / 2) rgba(0, 0, 0, .1);
  cursor: pointer;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: $fz;
    height: 4px;
    transform: translate(-50%, -50%);
    transition: all $ms;
    background-color: $color8;
    content: '';
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: $fz;
    height: 4px;
    transform: translate(-50%, -50%) rotate(90deg);
    transition: all $ms;
    background-color: $color8;
    content: '';
  }

  &:hover {
    background-color: $color1;

    &::before {
      transform: translate(-50%, -50%) rotate(-90deg);
      background-color: #fff;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(180deg);
      background-color: #fff;
    }
  }
}
