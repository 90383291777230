/* Grid */
$wrapper: 1248px !default;
$gutter: 1.5rem !default;

@mixin row($inside: false) {
  @if $inside {
    margin-right: (-$gutter / 2);
    margin-left: (-$gutter / 2);
  }
  @else {
    width: 100%;
    max-width: $wrapper;
    margin-right: auto;
    margin-left: auto;

    // this has been added here but
    // its not part of the grid framework that i has been using for years
    // hopefully it wont od a big mess
    // matt requested to increase spacing globally
    // this seems to be the most apropriate place for it
    // personally — im not big fan of it
    padding: $lh / 2;

    @include mq(x-large) {
      max-width: $x-wrapper;
    }
  }

  &::after {
    display: block;
    clear: both;
    content: '';
  }
}

@mixin col($width: 1) {
  width: percentage($width);
  padding-right: ($gutter / 2);
  padding-left: ($gutter / 2);
  float: left;
}

@mixin push($width: 0) {
  position: relative;
  left: percentage($width);
}

@mixin pull($width: 0) {
  position: relative;
  right: percentage($width);
}
