.contact-form {
  @include row(true);

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  select,
  textarea {
    display: block;
    width: 100%;
    height: $lh * 2;
    padding: 0 ($lh / 3);
    transition: all $ms;
    border: 1px solid #ccc;
    border-radius: 0;
    outline: 0;
    background: #fff;
    color: #888;
    font: inherit;
    font-size: $fz / $type-scale;
    font-weight: 700;
    appearance: none;

    &:focus {
      border: 1px solid $color1;
      box-shadow: inset 0 0 0 1px $color1;
    }
  }

  input[type='checkbox'] {
    vertical-align: middle;
  }

  br {
    display: none;
    height: 0;
  }

  .wpcf7-form-control-wrap {
    display: block;
    margin-bottom: $lh / 2;
  }

  .wpcf7-form-control-wrap.acceptance-123 {
    display: inline-flex;
    position: relative;
    top: 4px;
    width: 28px;
    height: 28px;
    vertical-align: top;
    margin-right: $lh / 4;

    input {
      width: 28px;
      height: 28px;
      border: 2px solid #C1C1C1;
      border-radius: 3px;
      background:white;
      -webkit-appearance:none;
      outline: none;
      cursor: pointer;
    }

    input:checked {
      background-image: url('data:image/svg+xml;charset=UTF-8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="#f60" d="M927.936 272.992l-68.288-68.288c-12.608-12.576-32.96-12.576-45.536 0l-409.44 409.44-194.752-196.16c-12.576-12.576-32.928-12.576-45.536 0l-68.288 68.288c-12.576 12.608-12.576 32.96 0 45.536l285.568 287.488c12.576 12.576 32.96 12.576 45.536 0l500.736-500.768c12.576-12.544 12.576-32.96 0-45.536z"></path></svg>');
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-position: center;
      border: 2px solid $color1;
    }

  }

  textarea {
    @include mq(medium) {
      height: $lh * 9.5;
    }
  }

  .wpcf7-list-item {
    margin: 0;
  }

  .ajax-loader {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }

  input[type='submit'] {
    // display: inline-block;
    width: auto;
    height: 76px;
    padding: 0 ($lh * 2);
    transition: all $ms;
    border: 1px solid $color1;
    border-radius: 0;
    outline: 0;
    background: #fff;
    background-color: $color1;
    color: #fff;
    font: inherit;
    font-size: $fz / $type-scale;
    font-weight: 700;
    line-height: 76px;
    cursor: pointer;
    appearance: none;
    margin-right: $lh / 2;

    &:hover {
      border: 1px solid $color-text;
      background-color: $color-text;
    }

    &:focus {
      border: 1px solid $color-text;
      background-color: $color-text;
      box-shadow: inset 0 0 0 1px $color-text;
    }
  }

    /* stylelint-disable */
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #888;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: #888;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: #888;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: #888;
    }
    /* stylelint-enable */
}

.contact-form__col {
  margin-bottom: $lh;
  @include col;

  @include mq(medium) {
    margin-bottom: 0;
    @include col(1 / 2);
  }
}

.contact-form__full {
  margin-bottom: $lh / 2;
  font-size: $fz / $type-scale;
  @include col;
}

.contact-form__full--flex {
  @include mq(medium) {
    display: flex;
    flex-direction: row-reverse;
  }
}
