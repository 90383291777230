.contact-details {
  @include row(true);
}

.contact-details__col {
  @include col;
  margin-bottom: $lh;

  @include mq(large) {
    @include col(1 / 2);
  }
}

.contact-details__description {
  font-size: $fz / $type-scale;
}

.contact-details__link {
  color: #000;
  font-weight: 700;
  transition: all $ms;

  &:hover {
    color: $color1;
    text-decoration: none;
  }
}
