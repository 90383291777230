.value {
  @include row;
}

.value--flip {
  @include mq(large) {
    .value__col:nth-of-type(1) {
      @include push(1/2);
    }

    .value__col:nth-of-type(2) {
      @include pull(1/2);
    }
  }
}

.value__col {
  @include col;
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  @include mq(large) {
    @include col(1/2);
  }
}
