.results {
  text-align: center;
  @include row;
}

.results__header,
.results__items {
  @include col;
}

.results__itemscontainer {
  @include row(true);
}

.results__item {
  @include col;

  @include mq(medium) {
    @include col(1 / 2);
  }

  @include mq(large) {
    @include col;

    @for $i from 2 through 4 {
      @include atleast(#{$i}) {
        @include col(1 / $i);
      }
    }
  }
}

.results__title {
  margin-bottom: $lh * 2;
  padding-bottom: $lh / 2;
  color: inherit;
  font-weight: 700;
  letter-spacing: -.02em;
  text-align: left;
  box-shadow: 0 1px 0 0 currentColor;
}

.results__top {
  margin-bottom: $lh;
  font-family: 'Helvetica Neue LT W04_81488930', sans-serif;
  font-size: $fz * $type-scale * $type-scale * $type-scale;
  letter-spacing: -.02em;

  @include mq(max) {
    font-size: $fz * $type-scale * $type-scale * $type-scale * $type-scale;
  }
}


.results__small {
  margin-bottom: $lh * 2;
  font-size: $fz / $type-scale;
  font-weight: 700;
  letter-spacing: -.02em;
  line-height: $lh * .75;
}
