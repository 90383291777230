.tags {
  margin-bottom: $lh;
}

.tags__link {
  display: inline-block;
  color: #000;
  font-weight: 700;
  font-size: $fz / $type-scale;
  margin: 0 ($lh / 3);

  &:hover {
    color: $color1;
    text-decoration: none;
  }
}
