.block {
  margin-bottom: $lh * 1.25;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.block--portfolio:last-child {
  margin-bottom: -$lh * 2.5;
}
