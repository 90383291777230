.share {
  text-align: center;
}

.share__title {
  color: $color8;
  font-weight: 700;
}

.share__btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $lh * 2.5;
  height: $lh * 2.5;
  margin: 0 ($lh / 4);
  border-radius: 50%;
  box-shadow: 0 0 0 1px $color8;
  transition: background $ms;

  &:hover {
    background-color: $color8;

    .share__icon {
      fill: #fff;
    }
  }
}

.share__icon {
  width: $lh * .75;
  height: $lh * .75;
  fill: $color8;
  transition: fill $ms;
}
