// Align
.u-alignleft {
  text-align: left;
}

.u-alignright {
  text-align: right;
}

.u-aligncenter {
  text-align: center;
}

.u-alignjustify {
  text-align: justify;
}

// Floats
.u-floatleft {
  float: left;
}

.u-floatright {
  float: right;
}

// Margins
.u-mt {
  margin-top: $lh;
}

.u-mt2 {
  margin-top: $lh * 2;
}

.u-mt3 {
  margin-top: $lh * 3;
}

.u-mb {
  margin-bottom: $lh;
}

.u-mb2 {
  margin-bottom: $lh * 2;
}

.u-mb3 {
  margin-bottom: $lh * 3;
}

// Padding
.u-pt {
  padding-top: $lh;
}

.u-pt2 {
  padding-top: $lh * 2;
}

.u-pt3 {
  padding-top: $lh * 3;
}

.u-pb {
  padding-bottom: $lh;
}

.u-pb2 {
  padding-bottom: $lh * 2;
}

.u-pb3 {
  padding-bottom: $lh * 3;
}

// underline
.u-underline {
  padding-bottom: $lh / 2;
  box-shadow: 0 1px 0 0 currentColor;
}
