@import url('https://fast.fonts.net/lt/1.css?apiType=css&c=7d6e9e79-1817-4c8d-9b0e-cc48151585cf&fontids=1488930');

@font-face {
  font-family: 'Helvetica Neue LT W04_81488930';
  src: url('../fonts/4a203c4f-952b-4b62-9bad-b2943a6f63aa.eot?#iefix');
  src: url('../fonts/4a203c4f-952b-4b62-9bad-b2943a6f63aa.eot?#iefix') format('eot'),
    url('../fonts/7349e981-d20d-4e13-aebc-27e74725147f.woff2') format('woff2'),
    url('../fonts/5da0f8c7-dd4d-41bd-849a-95224dca4902.woff') format('woff'),
    url('../fonts/394b4be6-942b-4f22-bf42-4e4877016d09.ttf') format('truetype');
}

::selection {
  background: $color1;
  color: #fff;
  text-shadow: none;
}

html {
  height: 100%;
  background-color: $color8;
  font-size: 112.5%; // 18px
  font-weight: 300;
  box-sizing: border-box;
  text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;

  @include mq(medium) {
    font-size: 125%; // 20px
  }

  @include mq(large) {
    font-size: 150%; // 24px
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }

  @include mq(x-large) {
    font-size: 175%; // 28px
  }
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  height: 100%;
  background-color: $color-bg;
  color: $color-text;
  font-family: $ff;
  font-size: $fz;
  line-height: $lh;

  @if $vertical-rythm == true {
    background-image: linear-gradient(rgba(50, 50, 50, .1) 1px, transparent 1px);
    background-size: 100% $lh;
  }
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  border: 0;
}
