@keyframes navItemPop {
  0% {
    transform: translateX((-$lh / 2));
    opacity: 0;
  }

  100% {
    transform: none;
    opacity: 1;
  }
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(-100%, 0, 0);
  transition: all $ms * 2;
  background: #fff;
  z-index: 20;

  @include mq(large) {
    width: 40%;
  }

  @include mq(x-large) {
    width: 30%;
  }

  @include mq(large) {
    &::before {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 200px;
      height: 110%;
      transform-origin: 100% 100%;
      transition: all $ms * 2;
      background: #fff;
      content: '';
      pointer-events: none;
    }
  }

  &.nav--open {
    transform: none;
    box-shadow: 0 $lh $lh 0 rgba(0, 0, 0, .08);
    opacity: 1;

    @include mq(large) {
      &::before {
        transform: rotate(7deg);
        box-shadow: 0 30px 0 0 #fff, -30px 30px 0 0 #fff, 50px 30px 65px 0 rgba(0, 20, 20, .16);
      }
    }

    .nav__item {
      animation-name: navItemPop;
      animation-duration: $ms * 2;
      animation-fill-mode: both;

      @for $i from 1 through 15 {
        &:nth-child(#{$i}) {
          animation-delay: ($ms * $i / 4) + $ms;
        }
      }
    }
  }
}

.nav__container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: ($lh * 2.5) ($lh * 1.5) ($lh * 1.5);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;


  &::-webkit-scrollbar {
    display: none;  // Safari and Chrome
  }
}

.nav__btn {
  position: absolute;
  top: $lh / 4;
  left: $lh / 4;
  width: $lh * 1.5;
  height: $lh * 1.5;
  transition: transform $ms;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  will-change: transform;

  &:hover {
    transform: rotate(90deg);

    .nav__bar {
      background: $color1;
    }
  }

  &:focus,
  &:active {
    .nav__bar {
      &:nth-child(1) {
        transform: rotate(-45deg);
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }
    }
  }
}

.nav__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%);
}

.nav__bar {
  position: absolute;
  top: 10px;
  left: 0;
  width: 24px;
  height: 4px;
  transition: all $ms cubic-bezier(.29,.69,.43,1.61);
  background: #000;

  &:nth-child(1) {
    transform: rotate(-45deg);
  }

  &:nth-child(2) {
    transform: rotate(45deg);
  }
}

.nav__list {
  width: 100%;
  margin: 0;
  transition: all $ms;
  font-weight: 700;
  letter-spacing: -.02em;
  list-style: none;
}

.nav__item {
  position: relative;
}

.nav__link {
  display: block;
  width: 100%;
  transition: all 140ms;
  color: $color-text;
  font-weight: inherit;
  line-height: $lh * 1.5;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  // increased specificity intentionally
  &.current-menu-item.current-menu-item,
  &.current-menu-ancestor.current-menu-ancestor {
    color: $color1;
  }

  &.nav__link--small {
    font-family: $ff;
    font-size: $fz / $type-scale;
    font-weight: 400;
    letter-spacing: 0;
  }

  &:focus,
  &:active {
    color: $color-text;
    text-decoration: none;

    & + .nav__arrow {
      fill: $color-text;
    }
  }

  &:hover {
    color: $color1;
    text-decoration: none;

    & + .nav__arrow {
      fill: $color1;
    }
  }
}

.nav__arrow {
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: $lh * 1.5;
  transition: transform 140ms;
  fill: #000;
  pointer-events: none;

  .nav__item--open & {
    transform: rotate(180deg);
  }
}

.nav__sublist {
  max-height: 0;
  margin: 0 0 0 $lh;
  transition: max-height $ms;
  font-weight: 400;
  list-style: none;
  overflow: hidden;
}
