.awards {
  @include row(true);
}

.awards__col {
  @include col(1/2);

  @include mq(medium) {
    @include col(1/3);
  }

  @include mq(large) {
    @include col(1/5);
  }
}

.awards__name {
  height: $lh * 3;
  margin-bottom: $lh / 2;
  font-size: $fz / $type-scale;
  font-weight: 700;
  line-height: $lh * .75;
  text-align: center;

  @include mq(large) {
    font-size: $fz / $type-scale / $type-scale;
  }
}

.awards__logo {
  width: 100%;
  height: $lh * 4.5;
  margin-bottom: $lh / 2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
