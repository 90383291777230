.intro {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #fff;
  z-index: 31;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color8, .9);
    content: '';
    z-index: -1;
  }

  // yes, it is needed here
  // on iOS video is removed and i dant want to have see-through layer
  // after <- see-through one
  // video <- nice video, no video on iOS (onde day)
  // before <- plain dark gray layer
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color8;
    content: '';
    z-index: -2;
  }
}

@keyframes IntroFadeOut {
  0% {
    transform: none;
    opacity: 1;
  }

  100% {
    transform: scale(1.1);
    opacity: 0;
  }
}

.intro--show {
  display: flex;
}

.intro--fadeOut {
  animation-name: IntroFadeOut;
  animation-duration: $ms * 6;
  animation-fill-mode: both;
}

.intro--hide {
  display: none;
}

.intro__logo {
  display: block;
  width: 100%;
  max-width: $lh * 12;
  margin: 0 auto ($lh * 2);
}

@keyframes introLogoTop {
  0% {
    transform: translate3d(105%, 0, 0);
    opacity: 0;
  }

  100% {
    transform: none;
    opacity: 1;
  }
}

.intro__logotop {
  fill: #fff;
  will-change: transform;
  animation-name: introLogoTop;
  animation-duration: $ms * 5;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-delay: $ms * 5;
  animation-fill-mode: both;
}

@keyframes introLogoBottom {
  0% {
    transform: translate3d(-105%, 0, 0);
    opacity: 0;
  }

  100% {
    transform: none;
    opacity: 1;
  }
}

.intro__logobottom {
  fill: #fff;
  will-change: transform;
  animation-name: introLogoBottom;
  animation-duration: $ms * 5;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-delay: $ms * 5;
  animation-fill-mode: both;
}

@keyframes showIntroText {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.intro__text {
  position: relative;
  animation-name: showIntroText;
  animation-duration: $ms * 10;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-delay: $ms * 10;
  animation-fill-mode: both;

  letter-spacing: -.02em;
  font-family: 'Helvetica Neue LT W04_81488930', sans-serif;
  font-size: $fz * $type-scale * $type-scale;
  line-height: $lh * 1.5;
  text-align: center;

  @include mq(large) {
    font-size: $fz * $type-scale * $type-scale * $type-scale * $type-scale;
    line-height: $lh * 2.5;
  }
}

.intro__video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
}
