.carousel {
  position: relative;
}

.carousel__nav {
  display: block;
  position: absolute;
  top: 50%;
  width: $lh * 2;
  height: $lh * 2;
  border: 0;
  border-radius: 50%;
  outline: 0;
  background-color: #fff;
  background-image: url('../images/carousel-icon.svg');
  background-size: $lh $lh;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 0 ($lh / 4) ($lh / 2) rgba($color8, .15);
  cursor: pointer;
  transform: translateY(-50%);
  transition: all $ms;

  &:hover {
    background-color: $color1;

    .carousel__icon {
      fill: #fff;
      transform: scale(1.25);
    }

    .carousel__icon--prev {
      transform: scale(1.25) rotate(180deg);
    }
  }
}

.carousel__nav--inactive {
  opacity: 0;
}

.carousel__nav--prev {
  left: $lh / 2;
  transform: translateY(-50%) rotate(180deg);
  box-shadow: 0 (0 - $lh / 4) ($lh / 2) rgba($color8, .15);

  @include mq(max) {
    left: -$lh;
  }
}

.carousel__nav--next {
  right: $lh / 2;

  @include mq(max) {
    right: -$lh;
  }
}

.carousel__media {
  display: block;
  margin: 0;
  width: 100%;
}

.carousel__vimeo {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.carousel__vimeo div {
  position: absolute!;
  width: 100%;
 height: 100%;
}
.carousel__iframe {
 position: absolute;
 top:0;
 left:0;
 height:100%;
 width:100%;}
