@keyframes memberAnimateIn {
  0% {
    transform: scale(1.1);
    opacity: 0;
  }

  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes memberAnimateOut {
  0% {
    transform: none;
    opacity: 1;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes showMemberImage {
  0% {
    transform: translateX($lh) scale(1.03);
    opacity: 0;
  }

  100% {
    transform: none;
    opacity: 1;
  }
}

.member {
  display: none;
}

.member--show {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 20;
}

.member--animatein {
  animation-name: memberAnimateIn;
  animation-duration: $ms * 2;
  animation-fill-mode: both;
}

.member--animateout {
  animation-name: memberAnimateOut;
  animation-duration: $ms * 2;
  animation-fill-mode: both;
}

.member__image {
  display: none;
}

.member__image--active {
  @include mq(large) {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    background-position: center;
    background-size: cover;
    animation-name: showMemberImage;
    animation-duration: $ms * 3;
    animation-delay: $ms * 3;
    animation-fill-mode: both;
  }
}

.member__middle {
  width: 100%;
}

.member__row {
  @include row;
}

.member__col {
  @include col;

  @include mq(large) {
    @include col(1/2);
  }
}

.member__top {
  display: none;

  @include mq(medium) {
    display: block;
  }
}

.member__name {
  color: $color8;
  font-size: $fz * $type-scale * $type-scale * $type-scale;
  font-weight: 700;
  letter-spacing: -.02em;
  line-height: $lh * 2;
}

.member__role {
  margin-bottom: $lh;
  color: $color8;
  font-size: $fz * $type-scale;
}

.member__btn {
  position: absolute;
  top: $lh / 2;
  right: $lh / 2;
  width: $lh * 1.5;
  height: $lh * 1.5;
  transition: transform $ms;
  border: none;
  border-radius: 50%;
  outline: none;
  background: #fff;
  box-shadow: 0 0 $lh 0 rgba(0, 0, 0, .2);
  cursor: pointer;

  &:hover {
    transform: rotate(90deg);

    .member__bar {
      background: $color1;
    }
  }

  &:focus,
  &:active {
    .member__bar {
      &:nth-child(1) {
        transform: rotate(-45deg);
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }
    }
  }
}

.member__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%);
}

.member__bar {
  position: absolute;
  top: 10px;
  left: 0;
  width: 24px;
  height: 4px;
  transition: all $ms cubic-bezier(.29,.69,.43,1.61);
  background: #000;

  &:nth-child(1) {
    transform: rotate(-45deg);
  }

  &:nth-child(2) {
    transform: rotate(45deg);
  }
}
