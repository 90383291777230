.header {
  display: flex;
  position: fixed;
  top: $lh * 2.5;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: $headerHeight;
  margin-top: -$lh * 2.5;
  transition: all $ms * 3;
  z-index: 19;
  fill: #fff;

  @include mq(medium) {
    justify-content: center;
  }
}

.header--dark {
  background-color: rgba(#fff, .75);
  box-shadow: 0 0 20px 0 rgba(0, 20, 20, .08);
  fill: #000;

  .header__bar {
    background: #000;
  }
}

.header--hide {
  transform: translateY(-100%);
  opacity: 0;
}

.header__btn {
  position: absolute;
  top: $lh / 4;
  left: $lh / 4;
  width: $lh * 1.5;
  height: $lh * 1.5;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;

  &:hover {
    .header__bar {
      &:nth-child(1) {
        transform: translateX(3px);
      }

      &:nth-child(2) {
        transform: translateX(-3px);
      }

      &:nth-child(3) {
        transform: translateX(3px);
      }
    }
  }
}

.header__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%);
}

.header__bar {
  position: absolute;
  left: 0;
  width: 24px;
  height: 4px;
  transition: all $ms cubic-bezier(.29,.69,.43,1.61);
  background: #fff;

  &:nth-child(1) {
    top: 2px;
  }

  &:nth-child(2) {
    top: 10px;
  }

  &:nth-child(3) {
    top: 18px;
  }
}

.header__link {
  display: inline-block;
  height: $lh * 1.25;
  padding: 0 ($lh / 2);
  transition: opacity $ms;

  &:hover {
    opacity: .75;
  }
}

.header__logo {
  height: $lh * 1.25;
  fill: inherit;
  will-change: opacity;
}
