.subservice {
  margin-bottom: $lh * 2;

  @include mq(large) {
    &:nth-child(even) {

      .subservice__col:nth-child(1) {
        @include push(1 / 2);
      }

      .subservice__col:nth-child(2) {
        @include pull(1 / 2);
      }
    }
  }
}

.subservice__row {
  @include row;
}

.subservice__col {
  @include col;

  @include mq(large) {
    @include col(1 / 2);
  }
}

.subservice__imagecontainer {
  display: block;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  background: $color1;
  position: relative;

  &:hover {
    .subservice__image {
      opacity: .5;
    }

    .subservice__eye {
      transform: scale(1);
    }
  }
}

.subservice__image {
  transition: all $ms;
  border-radius: 50%;
}

.subservice__eye {
  position: absolute;
  width: $lh * 1.6;
  height: $lh * 1.6;
  top: calc(50% - (#{$lh} * .8));
  left: calc(50% - (#{$lh} * .8));
  fill: #fff;
  transform: scale(0);
  transition: transform $ms;
}
