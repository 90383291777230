.layer {
  position: relative;
  width: 100%;
  background: #fff;
  background-position: center;
  background-size: cover;
  box-shadow: 0 30px 70px 0 rgba(0, 20, 20, 0.16);
  overflow: visible;

  @for $i from 1 through 20 {
    &:nth-child(#{$i}) {
      z-index: 20 - $i;

      .layer__content {
        z-index: 20 - $i + 1;
      }
    }
  }

  &.layer--cutup {
    &::before {
      display: block;
    }
  }

  &.layer--cutdown {
    box-shadow: none;

    & + .layer .layer__content {
      padding-top: calc(3rem); // this one need a bit on math
    }

    &::after {
      display: block;
    }

    & + .layer {
      .layer__content {
        padding-top: calc(3rem + 7vw);
      }
    }
  }
}

.layer--lightgray {
  background: $color9;
}

.layer--darkgray {
  background: $color8;
  color: #fff;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
  }
}

.layer__content {
  position: relative;
  padding: ($lh * 2) 0 $lh;
  background: inherit;
}

.layer::before,
.layer::after {
  display: none;
  position: absolute;
  width: 150%;
  height: 100%;
  transform: rotate(-7deg);
  content: "";
}

.layer::before {
  top: 0;
  left: 0;
  transform-origin: 0 0;
  background: inherit;
}

.layer::after {
  right: 0;
  bottom: 0;
  transform-origin: 100% 100%;
  background: inherit;
  box-shadow: 0 30px 70px 0 rgba(0, 20, 50, 0.16);
}

.our-values .hero + .layer {
  background-image: url("../images/values-left.png"),
    url("../images/values-right.png");
  background-size: auto auto;
  background-repeat: no-repeat;
  background-position: calc(100% - (1164px + ((100vw - 1164px) / 2))),
    calc(1164px + ((100vw - 1164px) / 2));

  @include mq(retina) {
    background-image: url("../images/values-left@2x.png"),
      url("../images/values-right@2x.png");
  }

  @include mq(x-large) {
    background-position: calc(100% - (1464px + ((100vw - 1464px) / 2))),
      calc(1464px + ((100vw - 1464px) / 2));
  }

  &::before,
  &::after {
    background: #fff;
  }
}
