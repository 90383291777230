// Base typography settings
// To read more about typography scale
// have a look at http://type-scale.com/
$fz: 0.8rem;
$lh: 1.2rem;
$ff: sans-serif;
$type-scale: 1.3333;

// Show vertical rythm
$vertical-rythm: false;

// Grid
$wrapper: 1164px; // (65 + 32) * 12
$x-wrapper: 1464px; // (80 + 42) * 12
$gutter: $lh;

// Breakpoints
$breakpoints: (
  'medium': (min-width: 668px),
  'large': (min-width: 960px),
  'x-large': (min-width: 1920px),
  'max': (min-width: $wrapper),
  'retina': (min-resolution: 1.3dppx)
);

// Colors
$color-text: #575757;
$color-bg: #fff;
$color1: #f60; // orange
$color2: #a6c240; // green
$color3: #9c2861; // purple
$color4: #c53032; // red
$color5: #ffce00; // yellow
$color6: #41c2d3; // blue
$color7: #3fd0ba; // turquoise
$color8: #24252B; // super dark gray
$color9: #edeef0; // super light gray
$color10: #333; // another gray
$color11: #555763; // gray with blue tint

// Animations & transitions
$ms: 140ms;

// Header
$headerHeight: $lh * 2;

// Services theming
$services: (
  'ar': $color1,
  'vr': $color4,
  'apps': $color3,
  'websites': $color2,
  'content': $color6,
  'strategy': $color7,
  'campaigns': $color5
);
