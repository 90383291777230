@keyframes arrowAnimation {
  9% {
    transform: none;
  }

  30% {
    transform: translateY(6px) scale(0.9);
  }

  100% {
    transform: none;
  }
}

.hero {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: $color8;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;

  .ios & {
    background-attachment: scroll;
  }
}

.hero__tint {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero--center {
  text-align: center;
}

.hero__middle {
  position: fixed;
  top: 50%;
  width: 100%;
  padding: ($lh * 4) 0;
  transform: translateY(-50%);
  will-change: transform;
}

.hero__top {
  margin-bottom: $lh / 2;
  padding-bottom: $lh / 2;
  box-shadow: inset 0 -1px 0 0 #fff;
}

.hero__title {
  margin-bottom: 0;
  padding-bottom: $lh * 0.5;
  color: #fff;
  font-family: inherit;
  font-weight: inherit;
  font-size: $fz * $type-scale;
  line-height: inherit;
  box-shadow: inset 0 -1px 0 0 #fff;

  @include mq(large) {
    padding-bottom: $lh * 0.35;
    font-size: $fz * $type-scale;
    line-height: $lh * 1.5;
  }
}

.hero__description {
  margin-top: $lh * 0.5;
  color: #fff;
  font-family: "Helvetica Neue LT W04_81488930", sans-serif;
  font-size: $fz * $type-scale * $type-scale;
  line-height: $lh * 1.5;
  letter-spacing: -0.02em;

  @include mq(medium) {
    font-size: $fz * $type-scale * $type-scale * $type-scale;
    line-height: $lh * 2;
  }

  @include mq(large) {
    margin-top: $lh * 0.35;
    font-size: $fz * $type-scale * $type-scale * $type-scale * $type-scale;
    line-height: $lh * 2.25;
  }
}

.hero__scroll {
  display: none;

  @include mq(large) {
    display: block;
    position: absolute;
    bottom: $lh / 4;
    left: 50%;
    transform: translateX(-50%);
    transition: color $ms;
    border: 0;
    outline: 0;
    background-color: transparent;
    font: inherit;
    font-weight: 700;
    font-size: $fz / $type-scale / $type-scale;
    letter-spacing: -0.02em;
    text-align: center;
    cursor: pointer;
    z-index: 19;
  }

  &:hover {
    color: $color1;
    fill: $color1;
  }
}

// .hero__scroll--white {
//   color: #fff;
//   fill: #fff;
// }

.hero__scrolltext {
  display: none;

  @include mq(max) {
    display: block;
  }
}

.hero__scrollicon {
  animation-name: arrowAnimation;
  animation-duration: $ms * 8;
  // animation-timing-function: linear|ease|ease-in|ease-out|cubic-bezier(n,n,n,n)|initial|inherit;
  animation-delay: 2000ms;
  animation-iteration-count: infinite;
  // animation-direction: normal|reverse|alternate|alternate-reverse|initial|inherit;
  animation-fill-mode: both;
  // animation-play-state: running|paused|initial|inherit;

  display: block;
  width: $fz;
  height: $fz;
  margin: 0 auto;
  transition: fill $ms;
}

.hero__video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  transform: translateX(-50%) translateY(-50%);
}

.hero__circle {
  display: flex;
  position: absolute;
  right: calc(50% - #{$lh * 2.5});
  bottom: -9vw;
  align-items: center;
  justify-content: center;
  width: $lh * 5;
  height: $lh * 5;
  border-radius: 50%;
  color: #fff;
  font-weight: 700;
  letter-spacing: -0.02em;
  box-shadow: 0 $lh $lh 0 rgba(0, 0, 0, 0.08);
  animation-timing-function: cubic-bezier(0.51, 0.78, 0.26, 1.45);
  will-change: transform;
  z-index: 19;

  @include mq(medium) {
    bottom: 6vw;
    right: 6vw;
  }
}

.hero__circletxt {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $lh * 4;
  height: $lh * 4;
  margin-bottom: 0;
  font-size: $fz / $type-scale;
  line-height: $lh * 0.5;
  text-align: center;
}

.hero__circleimg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
