.next {
  padding: ($lh * 2) 0;
}

.next--white {
  color: #fff;
}

.next__small {
  display: block;
  font-size: $fz / $type-scale;
  margin-bottom: $lh / 2;
}
