@keyframes showThumb {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hideThumb {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.06);
    opacity: 0;
  }
}

.thumb {
  @media screen and (min-width: 700px) {
    display: flex;
    position: fixed;
    top: 0;
    left: 40%;
    align-items: center;
    justify-content: flex-end;
    width: 60%;
    height: 100%;
    padding: $lh * 3;
    transition: all ($ms * 2);
    background-position: center;
    background-size: cover;
    z-index: 19;
    animation-name: showThumb;
    animation-duration: $ms * 2;
    animation-fill-mode: both;

    @include mq(x-large) {
      left: 30%;
      width: 70%;
    }

    &.hide {
      animation-name: hideThumb;
      animation-duration: $ms * 2;
      animation-fill-mode: both;
      animation-delay: $ms * 2;
    }
  }
}

.thumb__text {
  width: 100%;
  padding: ($lh / 2) 0;
  border-top: 1px solid #fff;
  color: #fff;
  font-weight: 700;
  font-size: $fz * $type-scale;
  letter-spacing: -.02em;
}
