.footer {
  position: relative;
  width: 100%;
  padding-top: $lh * 4;
  background: $color11;
  font-size: $fz / $type-scale;
  color: rgba(255, 255, 255, 0.5);

  @include mq(large) {
    font-size: $fz / $type-scale / $type-scale;
  }
}

.footer__badge {
  margin: 0 auto;
  height: $lh * 4;
  margin-bottom: $lh * 2;

  &-wrapper {
    position: relative;
  }
}

.footer__years {
  position: absolute;
  user-select: none;
  left: 50%;
  transform: translateX(-50%);
  font-size: 2.5rem;
  font-weight: 700;
  color: white;
  top: 1.75rem;
}

.footer__row {
  @include row;
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.25),
    0 -1px 0 0 rgba(255, 255, 255, 0.25);
}

.footer__nav {
  @include col;

  @include mq(large) {
    @include col(1 / 2);
  }
}

.footer__col {
  @include col;

  @include mq(medium) {
    @include col(1 / 2);
  }

  @include mq(large) {
    @include col(1 / 4);
  }
}

.footer__details {
  @include col;

  @include mq(medium) {
    @include col(1 / 2);
  }

  @include mq(large) {
    @include col(1 / 6);
  }
}

.footer__socials {
  @include col;

  @include mq(medium) {
    @include col(1 / 2);
  }
}

.footer__socialslist {
  margin: 0;
  list-style: none;

  @include mq(large) {
    // margin-top: $lh * 1.5;
    // text-align: right;
  }
}

.footer__socialsitem {
  display: inline-block;
}

.footer__socialslink {
  display: block;
  margin-right: $lh / 4;
  text-align: center;
}

.footer__socialsicon {
  display: block;
  height: $lh * 0.5;
  margin: 0;
  transition: fill $ms;
  fill: #fff;

  &:hover {
    fill: $color1;
  }
}

.footer__description {
  color: rgba(255, 255, 255, 0.3);
  line-height: $lh * 0.75;
}

.footer__link {
  display: block;
  margin-bottom: $lh / 2;
  transition: all $ms;
  color: #fff;
  font-weight: 700;
  line-height: $lh * 0.75;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    color: $color1;
    text-decoration: none;
  }
}

.footer__menu {
  margin: 0;
  padding-top: $lh / 2;
  list-style: none;
  @include row(true);

  li {
    @include col(1/2);
  }

  a {
    display: block;
    height: $lh;
    transition: all $ms;
    color: #fff;
    font-weight: 700;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
      color: $color1;
      text-decoration: none;
    }
  }
}
