.alignnone {
  max-width: 100%;
  height: auto;
  margin-right: 0;
  margin-left: 0;
}

.aligncenter {
  display: block;
  height: auto;
  margin: ($lh / 2) auto;
}

.alignleft,
.alignright {
  height: auto;
  margin-bottom: ($lh / 2);
}

@include mq(large) {
  .alignleft {
    margin-right: ($lh / 2);
    float: left;
  }
  .alignright {
    margin-left: ($lh / 2);
    float: right;
  }
}

.screen-reader-text {
  display: none;
}