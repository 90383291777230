strong {
  font-weight: 700;
  letter-spacing: -.02em;
}

em {
  font-style: italic;
}

a {
  background: transparent;
  color: $color1;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    outline: 0;
    text-decoration: underline;
  }
}

p {
  margin-bottom: $lh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: $lh;
  color: $color8;
  font-weight: 700;
  letter-spacing: -.02em;
}

h1 {
  font-size: $fz * $type-scale * $type-scale * $type-scale;
  line-height: $lh * 2;
}

h2 {
  font-size: $fz * $type-scale * $type-scale;
  line-height: $lh * 1.5;
}

h3 {
  font-size: $fz * $type-scale;
}

h4 {
  font-size: $fz;
}

h5 {
  font-size: $fz / $type-scale;
}

h6 {
  font-size: $fz / $type-scale / $type-scale;
}

ul,
ol {
  margin-bottom: $lh;
  margin-left: $lh;

  ul,
  ol {
    margin: 0 $lh;
  }
}

blockquote {
  margin-bottom: $lh;
  padding: $lh;
  border-left: 4px solid darken($color-bg, 10%);
  background: darken($color-bg, 5%);

  p {
    margin-bottom: 0;
    font-style: italic;
  }
}

hr {
  height: $lh;
  border: 0;
  box-shadow: inset 0 1px 0 $color-text;
}

pre {
  margin-bottom: $lh;
  padding: ($lh / 2) $lh;
  background: darken($color-bg, 5%);
  overflow-x: auto;
}
