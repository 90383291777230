@mixin mq($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(type-of($raw-query) == 'string', unquote($raw-query), inspect($raw-query));

    @media #{$query} {
      @content;
    }
  }
  @else {
    @media (min-width: $breakpoint) {
      @content;
    }
  }
}

// how to use:
// body {
//   font-size: 16px;
//   @include mq(large) {
//     font-size: 18px;
//   }
// }

@mixin transition($property: all) {
  transition: $property 120ms ease-out;
}

// how to use:
// @include transition(opacity);

@mixin clearfix() {
  &::before,
  &::after {
    display: table;
    content: '';
  }

  &::after {
    clear: both;
  }
}

// how to use:
// @include clearfix;


@mixin atleast($nth) {
  &:first-of-type:nth-last-of-type(n + #{$nth}),
  &:first-of-type:nth-last-of-type(n + #{$nth}) ~ & {
    @content;
  }
}

// how to use:
// .test {
//   float: left;
//   // styles for one element
//   width: 100%;

//   @for $i from 2 through 8 {
//     @include atleast(#{$i}) {
//       width: 100% / $i;
//     }
//   }
// }
