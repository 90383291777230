.team {
  @include row(true);
}

.team__item {
  margin-bottom: $lh;
  @include col;

  @include mq(medium) {
    @include col(1/2);
  }

  @include mq(large) {
    @include col(1/3);
  }
}

.team__btn {
  display: block;
  border: 0;
  outline: 0;
  background-color: transparent;
  position: relative;
  font: inherit;
  color: #000;

  &:hover {
    opacity: .5;

    .team__info {
      opacity: 1;
    }
  }
}

.team__img {
  border-radius: 50%;
  cursor: pointer;
  transition: opacity $ms;

  &:hover {
    opacity: .5;
  }
}

.team__info {
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  width: 100%;
  position: absolute;
  transition: opacity $ms;
  opacity: 0;
  pointer-events: none;
}

.team__infoname {
  font-weight: 700;
}

.team__inforole {
  font-size: $fz / $type-scale;
}

.team__hide {
  display: none;
}
