.work {
  display: block;
  position: relative;
  margin: 5px;
  transform: translateZ(0);
  background-color: darken($color8, 6);
  overflow: hidden;

  &::after {
    position: absolute;
    top: 100%;
    left: 0;
    width: 200%;
    height: 100%;
    transform: rotate(-7deg);
    transform-origin: 0 0;
    transition: transform $ms;
    background-color: darken($color8, 6);
    content: '';
  }

  &:hover {
    .work__content {
      opacity: 1;
    }

    .work__image {
      opacity: .25;
    }

    .work__eye {
      transform: scale(1);
    }
  }
}

.work__image {
  transition: opacity $ms;
  opacity: .75;

  .no-touchevents & {
    opacity: 1;
  }
}

.work__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: $lh / 2;
  transition: opacity $ms;
  color: #fff;
  z-index: 1;

  .no-touchevents & {
    opacity: 0;
  }
}

.work__title {
  margin-bottom: 0;
  color: #fff;
  font-family: sans-serif;
  font-weight: 700;
}

.work__logo {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 23%;
  max-height: 9%;
  z-index: 1;
}

.work__eye {
  position: absolute;
  width: $lh * 1.6;
  height: $lh * 1.6;
  top: calc(50% - (#{$lh} * .8));
  left: calc(50% - (#{$lh} * .8));
  fill: #fff;
  transform: scale(0);
  transition: transform $ms;
}
