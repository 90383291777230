.posts-navigation {
  margin-top: $lh * 2;
  text-align: center;

  .nav-links {
    display: flex;
    justify-content: space-between;
  }

  .nav-next {
    margin-left: auto;
  }

  a {
    display: inline-block;
    position: relative;
    padding: ($lh / 2) $lh;
    transition: all $ms;
    border: none;
    outline: none;
    background-color: transparent;
    background-image: linear-gradient(180deg, $color-text 0%, $color-text 50%, transparent 50%, transparent 100%);
    background-repeat: no-repeat;
    background-position: 0 -100%, center;
    background-size: 200% 200%;
    color: $color-text;
    font: inherit;
    font-size: $fz / $type-scale;
    font-weight: 700;
    letter-spacing: -.02em;
    box-shadow: inset 0 0 0 1px $color-text;
    cursor: pointer;

    &:hover {
      background-position: 0 0, center;
      color: #fff;
      text-decoration: none;
    }
  }
}
