.btn {
  display: inline-block;
  position: relative;
  padding: ($lh / 2) $lh;
  transition: all $ms;
  border: none;
  outline: none;
  background-color: transparent;
  background-image: linear-gradient(180deg, #fff 0%,#fff 50%, transparent 50%, transparent 100%);
  background-repeat: no-repeat;
  background-position: 0 -100%, center;
  background-size: 200% 200%;
  color: #fff;
  font: inherit;
  font-weight: 700;
  letter-spacing: -.02em;
  box-shadow: inset 0 0 0 1px #fff;
  cursor: pointer;

  @include mq(medium) {
    font-size: $fz / $type-scale;
  }

  &:hover {
    background-position: 0 0, center;
    color: $color-text;
    text-decoration: none;
  }
}

.btn--black {
  background-image: linear-gradient(180deg, $color-text 0%, $color-text 50%, transparent 50%, transparent 100%);
  color: $color-text;
  box-shadow: inset 0 0 0 1px $color-text;

  &:hover {
    color: #fff;
  }
}

.btn--short {
  padding: ($lh / 4) $lh;
}

.btn--orange {
  background-color: $color1;
  color: #fff;
  box-shadow: inset 0 0 0 1px $color1;

  &:hover {
    color: $color1;

    &::before {
      transform: none;
    }
  }

  &[disabled] {
    background-color: $color-text;
    color: $color8;
    box-shadow: none;

    &:hover {
      background-position: 0 -100%, center;
    }
  }
}
