.questions {
  --padding: 1.25rem;
  list-style: none;
  margin-inline: 0rem;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.accordion {
  background-color: hsla(0, 0, 85, 0.38);
  position: relative;
  text-align: left;
  width: 100%;

  &__title {
    padding: var(--padding);
    user-select: none;
    margin-bottom: 0;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  &__arrow {
    transition: transform 0.4s ease;
    display: block;
    width: 1rem;
    height: 1rem;
    position: absolute;
    right: 1.25rem;
    top: 1.6rem;

    polygon {
      fill: white;
    }
  }

  &__inner {
    transition: max-height 0.5s ease-in-out;
    max-height: 0;
    position: relative;
    overflow: hidden;

    > div {
      padding: var(--padding);
      padding-top: 0;
    }
  }
}

.accordion--open.accordion {
  .accordion__inner {
    max-height: 690px;
  }
  .accordion__arrow {
    transform: rotate(180deg);
  }
}
