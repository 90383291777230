.cinema {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color8, .95);
  z-index: 30;
  animation-name: processShow;
  animation-duration: $ms;
  animation-fill-mode: both;
}

.cinema--active {
  display: block;

  .cinema__center {
    animation-name: processShow;
    animation-duration: $ms * 3;
    animation-fill-mode: both;
    animation-delay: $ms * 3;
  }
}

.cinema--fadeout {
  animation-name: processfadeOut;
  animation-duration: $ms;
  animation-fill-mode: both;
}

.cinema__close {
  position: absolute;
  top: $lh / 2;
  right: $lh / 2;
  width: $lh * 2;
  height: $lh * 2;
  transform: rotate(45deg);
  transition: $ms all;
  border: 0;
  border-radius: 50%;
  outline: 0;
  background-color: #fff;
  font: inherit;
  font-weight: 700;
  letter-spacing: -.02em;
  box-shadow: 0 ($lh / 4) ($lh / 2) rgba(0, 0, 0, .1);
  cursor: pointer;
  z-index: 31;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: $fz;
    height: 4px;
    transform: translate(-50%, -50%);
    transition: all $ms;
    background-color: $color8;
    content: '';
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: $fz;
    height: 4px;
    transform: translate(-50%, -50%) rotate(90deg);
    transition: all $ms;
    background-color: $color8;
    content: '';
  }

  &:hover {
    background-color: $color1;

    &::before {
      transform: translate(-50%, -50%) rotate(-90deg);
      background-color: #fff;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(180deg);
      background-color: #fff;
    }
  }
}

.cinema__center {
  position: relative;
  width: 100%;
  max-width: 1280px;
  height: 100%;
  margin: 0 auto;
}

.cinema__video {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  max-width: 1280px;
  padding-bottom: 56.25%;
  transform: translateY(-50%);
  box-shadow: 0 0 0 2px #eee;
}

.cinema__iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
