.ie-alert {
  padding: $lh / 2;
  background: $color1;
  color: #fff;
  text-align: center;
  font-size: $fz / $type-scale;
}

.ie-alert__link {
  color: inherit;
  text-decoration: underline;
}
