.smallbubbles {
  width: 100%;
  margin: 0 auto $lh;
}

.smallbubbles--left {
  max-width: 620px;
}

.smallbubbles--right {
  max-width: 668px;
}

.smallbubbles__container {
  position: relative;
  width: 100%;
}

.smallbubbles__container--left {
  padding-bottom: 121.290322581%;
}

.smallbubbles__container--right {
  padding-bottom: 122.754491018%;
}

.smallbubbles__item {
  display: block;
  position: absolute;
  transition: transform ($ms * 1.5) cubic-bezier(.42,1.04,.51,1.34);
  border-radius: 50%;
  color: #fff;
  font-weight: 700;
  letter-spacing: -.02em;
  mix-blend-mode: multiply;

  &:hover {
    transform: scale(1.3) rotate(-10deg);
    z-index: 1;
  }
}

@each $service, $color in $services {
  .smallbubbles__item--#{$service} {
    background-color: #{$color};
  }
}

.smallbubbles__item--strategy {
  top: 23.9%;
  left: -0.2%;
  width: 44.516129032%;
  padding-bottom: 44.516129032%;
}

.smallbubbles__item--content {
  top: 0;
  left: 37.258064516%;
  width: 52.419354839%;
  padding-bottom: 52.419354839%;
}

.smallbubbles__item--campaigns {
  top: 43.25%;
  left: 31.819554849%;
  width: 68.225806452%;
  padding-bottom: 68.225806452%;
}

.smallbubbles__item--ar {
  top: 12.260127932%;
  left: 0;
  width: 54.790419162%;
  padding-bottom: 54.790419162%;
}

.smallbubbles__item--vr {
  top: -0.4%;
  left: 49%;
  width: 40.718562874%;
  padding-bottom: 40.718562874%;
}

.smallbubbles__item--apps {
  top: 32.43902439%;
  left: 50.299401198%;
  width: 49.401197605%;
  padding-bottom: 49.401197605%;
}

.smallbubbles__item--websites {
  top: 56.560976%;
  left: 8.928144%;
  width: 52.095808383%;
  padding-bottom: 52.095808383%;
  text-align: center;
}

.smallbubbles__name {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 0 ($lh / 2);
  transform: translateY(-50%);
  color: #fff;
  font-size: $fz / $type-scale;
  font-weight: 700;
  letter-spacing: -.02em;
  line-height: $lh * .75;
  text-align: center;

  @include mq(large) {
    padding: 0 $lh;
    font-size: $fz * $type-scale;
    line-height: $lh;
  }
}
