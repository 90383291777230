.spectrum {
  text-align: center;
  @include row(true);
}

.spectrum__col {
  margin-bottom: $lh;
  @include col();

  @include mq(large) {
    position: relative;
    @include col(1 / 3);

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background: $color9;
      content: '';
    }

    &:last-of-type::after {
      display: none;
    }
  }
}

.spectrum__logocontainer {
  width: 100%;
  height: $lh * 6;
  margin-bottom: $lh;
}

.spectrum__logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.spectrum__name {
  font-size: $fz * $type-scale;
}

.spectrum__pros {
  margin-bottom: $lh / 4;

  @include mq(large) {
    font-size: $fz / $type-scale;
  }
}

.spectrum__list {
  margin-left: 0;
  list-style: none;

  @include mq(large) {
    font-size: $fz / $type-scale;
  }
}
