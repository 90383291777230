.jobs {
  box-shadow: 0 -1px 0 0 $color8;
}

.jobs__item {
  box-shadow: inset 0 -1px 0 0 $color8;
}

.jobs__top {
  position: relative;
  padding: $lh 0;
}

.jobs__btn {
  @include mq(medium) {
    position: absolute;
    top: 0;
    right: 0;
    margin: ($lh * .75) 0;
  }
}

.jobs__row {
  @include row(true);
  max-height: 0;
  opacity: 0;
  transition: max-height $ms;
  overflow: hidden;
}

@keyframes showThisStuff {
  0% {
    opacity: 0;
    transform: translateX($lh / 3);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.jobs__row--show {
  animation-name: showThisStuff;
  animation-duration: $ms * 2;
  animation-delay: $ms * 2;
  animation-fill-mode: both;
}

.jobs__col {
  @include col;

  @include mq(large) {
    @include col(2/3);
  }
}

.jobs__title {
  margin-bottom: $lh;

  @include mq(medium) {
    margin-bottom: 0;
  }
}
