.brands {
  @include row(true);
}

.brands__vertical {
  margin-bottom: $lh;
  @include col(1/2);

  @include mq(medium) {
    @include col(1/3);
  }

  @include mq(large) {
    @include col(1/4);
  }

  @include mq(max) {
    @include col(1/6);
  }
}

.brands__industry {
  margin-bottom: $lh / 2;
  font-size: $fz / $type-scale;
  font-weight: 700;
  box-shadow: 0 1px 0 0 currentColor;

  @include mq(large) {
    font-size: $fz / $type-scale / $type-scale;
  }
}

.brands__logo {
  width: 100%;
  height: $lh * 3;
  margin-bottom: $lh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

// clients_logos_horizontal block
.brands--horizontal {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .brands__logo {
    max-width: 6.5rem;
    width: 25%;
    height: 4.5rem;
    margin-bottom: 0;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    display: block;
  }
}
